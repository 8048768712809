import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TerraformRoute extends Route {
  @service userContext;

  model() {
    let { unifiedExperienceEnabled } = this.userContext.organizationMeta;

    return { unifiedExperienceEnabled };
  }
}
