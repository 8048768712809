import PaginatedRoute from 'core/routes/paginated-route';
import { inject as service } from '@ember/service';
import { TYPE_TERRAFORM_WORKSPACE } from 'common/utils/cloud-resource-types';
import {
  ACTION_LIST,
  PREFIX_TERRAFORM_WORKSPACES,
} from 'authz/utils/permission-types/index';

export default class TerraformWorkspacesListRoute extends PaginatedRoute {
  @service api;
  @service permissions;
  @service userContext;

  async model({
    pageSize = 10,
    nextPageToken,
    previousPageToken,
    leafNameFilter = null,
  }) {
    const { id } = this.userContext.project;
    const resp =
      await this.api.resourceManager.resources.resourceServiceListAccessibleResources(
        `project/${id}`,
        undefined,
        TYPE_TERRAFORM_WORKSPACE,
        this.permissions.generate(PREFIX_TERRAFORM_WORKSPACES, ACTION_LIST),
        leafNameFilter,
        pageSize,
        nextPageToken,
        previousPageToken
      );
    return {
      ...resp,
      organizationId: this.userContext.organization.id,
      projectId: this.userContext.project.id,
    };
  }
}
